// noinspection JSUnusedLocalSymbols

import type { ContextRequestOptions, ListOptions } from '@main/api/plugin/types/api';
import { createMediaItem } from '@main/api/resources/media';
import {
    addProjectCollaborator,
    type AggregatableField,
    type ApiAssistantConversation,
    type ApiAssistantConversationMessage,
    type ApiCollaborator,
    type ApiContextSummary,
    type ApiProject,
    type ApiProjectIncludes,
    type ApiProjectPhase,
    type ApiProjectWithPhases,
    createAssistantConversation,
    createAssistantSetupConversation,
    createProject,
    deleteProject,
    fetchAssistantConversation,
    fetchAssistantConversationMessages,
    fetchAssistantConversations,
    fetchAssistantSetupConversation,
    fetchLatestAssistantConversation,
    fetchProject,
    fetchProjectCollaborator,
    fetchProjectCollaborators,
    fetchProjectPhases,
    fetchProjects,
    type NewApiProject,
    type ProjectPermissionsGrants,
    removeProjectCollaborator,
    sendAssistantConversationCorkMessage,
    sendAssistantConversationMessage,
    type UpdatedApiProject,
    updateProject,
    updateProjectCollaborator,
} from '@main/api/resources/projects';
import { Project } from '@main/domain/projects/Project';
import { useTrackingStore } from '@main/store/stores/tracking';
import type { ResizeOptions } from '@main/utilities/files';
import { sleep } from '@main/utilities/misc';
import { patchList } from '@main/utilities/store';
import { acceptHMRUpdate, defineStore } from 'pinia';
import Vue from 'vue';

export type PendingMessage = Pick<
    ApiAssistantConversationMessage,
    'content' | 'suggestions' | 'uuid' | 'role' | 'group'
> & {
    failed: boolean;
    sent: boolean;
    status?: string;
};

type State = {
    abortController: AbortController | undefined;
    aggregations: Partial<Record<AggregatableField, Record<string, number>>>;
    apiCollaborators: Record<string, ApiCollaborator[]>;
    apiProjects: ApiProjectWithPhases[];
    assistantPending: boolean;
    conversationMessages: Record<string, ApiAssistantConversationMessage[]>;
    conversations: Record<string, ApiAssistantConversation[]>;
    createProjectActive: boolean;
    pendingMessages: PendingMessage[];
    pollingTimeout: number | undefined;
    setupConversations: Record<string, string>;
    summary: Record<string, ApiContextSummary>;
    totalProjects: number;
    waitInterval: number;
};

export const useProjectsStore = defineStore( 'projects', {
    persist: false,

    state(): State {
        return {
            // These are the projects a user can paginate through in the projects list.
            apiProjects: [],
            totalProjects: 0,

            // All collections are stored per project UUID
            apiCollaborators: {},

            aggregations: {},

            // Assistant conversation
            conversationMessages: {},
            conversations: {},
            setupConversations: {},

            pendingMessages: [],
            summary: {},

            abortController: undefined,
            assistantPending: false,
            pollingTimeout: undefined,
            waitInterval: 1000,

            createProjectActive: false,
        };
    },

    actions: {
        // region Projects

        async createProject( project: NewApiProject, image?: File ) {
            let mediaItemUuid: string | undefined = undefined;

            if ( image ) {
                const options: ResizeOptions = {
                    longestEdge: 1200,
                    jpegQuality: 0.72,
                };

                mediaItemUuid = await createMediaItem( image, 'projectImage', options );
            }

            const apiProject = await createProject( {
                imageUuid: mediaItemUuid,
                ...project,
            } );

            const trackingStore = useTrackingStore();
            trackingStore.sendProjectCreated( apiProject.uuid );

            // Fetch the phases and collaborators as we want them to always be available.
            const results: [ApiProjectPhase[], ApiCollaborator[]] = await Promise.all( [
                fetchProjectPhases( apiProject.uuid ),
                fetchProjectCollaborators( apiProject.uuid ),
            ] );

            const phases = results[0];
            const collaborators = results[1];

            // As opposed to phases, the collaborators are handled as
            // separate entities in the store, so we hydrate the phases ...
            const newProject: ApiProjectWithPhases = {
                ...apiProject,
                phases,
            };

            // ... and store the collaborators.
            Vue.set( this.apiCollaborators, apiProject.uuid, collaborators );

            // Now store the new project.
            this.apiProjects.unshift( newProject );

            return newProject;
        },

        async updateProject(
            projectUuid: string,
            data: UpdatedApiProject,
            image?: File,
        ): Promise<void> {
            let mediaItemUuid: string | undefined = undefined;

            if ( image ) {
                const options: ResizeOptions = {
                    longestEdge: 1200,
                    jpegQuality: 0.72,
                };

                mediaItemUuid = await createMediaItem( image, 'projectImage', options );
            }

            const updatedProject = await updateProject( projectUuid, {
                imageUuid: mediaItemUuid,
                ...data,
            } );

            const currentProject = this.apiProjects.find( ( { uuid } ) => uuid === projectUuid );

            const project = {
                ...updatedProject,
                // We shall have all phases.
                phases: currentProject?.phases || [],
                // Collaborators and entries are not necessarily available.
                collaborators: currentProject?.collaborators,
                entries: currentProject?.entries,
            };

            this.$patch( ( state ) => {
                state.apiProjects = patchList( state.apiProjects, project, 'uuid' );
            } );
        },

        async updateWizardState(
            projectUuid: string,
            wizardState: UpdatedApiProject['wizardState'],
        ) {
            await this.updateProject( projectUuid, { wizardState } );
        },

        async deleteProject( projectUuid: string ) {
            await deleteProject( projectUuid );

            this.removeDeletedProjectFromStore( projectUuid );
        },

        removeDeletedProjectFromStore( projectUuid: string ) {
            const updatedProjects = this.apiProjects.filter(
                ( project ) => project.uuid !== projectUuid,
            );

            this.$patch( ( state ) => {
                state.apiProjects = updatedProjects;
            } );

            this.totalProjects--;
        },

        async fetchProjects(
            options?: ListOptions<ApiProject> & { aggregations?: AggregatableField[]; },
        ) {
            if ( options?.filter?.tags ) {
                const tags = options.filter.tags;

                /**
                 * The user should be able to assign categories
                 * containing a comma, but still filter for
                 * multiple values per filter field. Since they
                 * are separated by comma, we encode the values
                 * and prefix them so our backend can distinguish.
                 */
                options.filter.tags = ( Array.isArray( tags ) ? tags : [tags] ).map(
                    ( t ) => 'base64:' + btoa( t ),
                );
            }

            const { projects, total, aggregations } = await fetchProjects( {
                aggregations: ['tags'], // Default value
                ...options,
                include: ['phases', 'collaborators'],
            } );

            this.totalProjects = total;

            // TODO Project filters: Generalize aggregations, since there will be others, too
            this.aggregations = aggregations;

            // We want to store the included collaborators separately,
            // so we split them from the resource.
            this.apiProjects = projects.map( ( project ) => {
                const { uuid, collaborators } = project;
                let withoutCollaborators = project as ApiProjectWithPhases;

                if ( collaborators ) {
                    Vue.set( this.apiCollaborators, uuid, collaborators );

                    withoutCollaborators = {
                        ...( project as ApiProjectWithPhases ),
                        collaborators: undefined,
                    };
                }

                return withoutCollaborators;
            } );
        },

        async fetchProjectSuggestions( companyUuid: string, term?: string ) {
            const params: ListOptions<ApiProject, ApiProjectIncludes> = {
                page: 1,
                include: ['phases', 'collaborators'],
            };

            if ( companyUuid ) {
                Object.assign( params, { companyUuid } );
            }

            if ( term ) {
                Object.assign( params, { filter: { name: term } } );

                return await fetchProjects( params );
            }

            Object.assign( params, { per_page: 5 }, { sort: ['-updatedAt'] } );

            return await fetchProjects( params );
        },

        async fetchProject(
            projectUuid: string,
            includes: ApiProjectIncludes[] = ['collaborators', 'phases'],
        ) {
            const project = await fetchProject( projectUuid, includes );

            this.apiProjects = patchList( this.apiProjects, project as ApiProjectWithPhases, 'uuid' );

            if ( project.collaborators ) {
                Vue.set( this.apiCollaborators, projectUuid, project.collaborators );
            }
        },

        // endregion

        // region Project Collaborators

        async addProjectCollaborator(
            projectUuid: string,
            userUuid: string,
            permissions: ProjectPermissionsGrants,
        ) {
            await addProjectCollaborator( projectUuid, userUuid, permissions );

            return this.fetchProjectCollaborators( projectUuid );
        },

        async updateProjectCollaborator(
            projectUuid: string,
            userUuid: string,
            permissions: ProjectPermissionsGrants,
        ) {
            await updateProjectCollaborator( projectUuid, userUuid, permissions );

            return this.fetchProjectCollaborators( projectUuid );
        },

        async removeProjectCollaborator( projectUuid: string, userUuid: string ) {
            await removeProjectCollaborator( projectUuid, userUuid );

            return this.fetchProjectCollaborators( projectUuid );
        },

        async fetchProjectCollaborators( projectUuid: string ) {
            const collaborators: ApiCollaborator[] = await fetchProjectCollaborators( projectUuid );

            Vue.set( this.apiCollaborators, projectUuid, collaborators );
        },

        async fetchProjectCollaborator( projectUuid: string, userUuid: string ) {
            const collaborator: ApiCollaborator = await fetchProjectCollaborator(
                projectUuid,
                userUuid,
            );

            this.apiCollaborators[projectUuid] = patchList(
                this.apiCollaborators[projectUuid],
                collaborator,
                'uuid',
            );
        },

        // endregion

        // region create project

        setCreateProjectActive( active: boolean ) {
            this.createProjectActive = active;
        },

        // endregion

        // region Assistant Conversation

        async createAssistantSetupConversation( projectUuid: string ) {
            const conversation = await createAssistantSetupConversation( projectUuid );

            Vue.set( this.setupConversations, projectUuid, conversation.uuid );

            Vue.set(
                this.conversations,
                projectUuid,
                patchList( this.conversations[projectUuid], conversation, 'uuid' ),
            );

            return conversation;
        },

        async fetchOrCreateAssistantSetupConversation( projectUuid: string ) {
            let conversation: ApiAssistantConversation;

            try {
                conversation = await fetchAssistantSetupConversation( projectUuid );
            } catch {
                conversation = await createAssistantSetupConversation( projectUuid );
            }

            Vue.set( this.setupConversations, projectUuid, conversation.uuid );

            Vue.set(
                this.conversations,
                projectUuid,
                patchList( this.conversations[projectUuid], conversation, 'uuid' ),
            );
        },

        async fetchAssistantSetupConversation( projectUuid: string ) {
            const conversation = await fetchAssistantSetupConversation( projectUuid );

            Vue.set( this.setupConversations, projectUuid, conversation.uuid );

            Vue.set(
                this.conversations,
                projectUuid,
                patchList( this.conversations[projectUuid], conversation, 'uuid' ),
            );

            return conversation;
        },

        async createAssistantConversation( projectUuid: string ) {
            const conversation = await createAssistantConversation( projectUuid );

            Vue.set(
                this.conversations,
                projectUuid,
                patchList( this.conversations[projectUuid], conversation, 'uuid' ),
            );

            return conversation;
        },

        async fetchAssistantConversation( projectUuid: string, conversationUuid: string ) {
            const conversation = await fetchAssistantConversation( projectUuid, conversationUuid );

            Vue.set(
                this.conversations,
                projectUuid,
                patchList( this.conversations[projectUuid], conversation, 'uuid' ),
            );

            return conversation;
        },

        async fetchLatestAssistantConversation( projectUuid: string ) {
            const conversation = await fetchLatestAssistantConversation( projectUuid );

            Vue.set(
                this.conversations,
                projectUuid,
                patchList( this.conversations[projectUuid], conversation, 'uuid' ),
            );

            return conversation;
        },

        async fetchAssistantConversations( projectUuid: string ) {
            const conversations = await fetchAssistantConversations( projectUuid );

            Vue.set( this.conversations, projectUuid, conversations );
        },

        async fetchAssistantConversationMessages(
            projectUuid: string,
            conversationId: string,
            options?: ContextRequestOptions,
        ) {
            const { messages, summary, pending } = await fetchAssistantConversationMessages(
                projectUuid,
                conversationId,
                options,
            );

            // Reverse the messages so the latest one is at the bottom
            messages.reverse();

            if ( !this.conversationMessages[conversationId] ) {
                Vue.set( this.conversationMessages, conversationId, {} );
            }

            Vue.set( this.conversationMessages, conversationId, messages );

            messages.forEach( ( message ) => this.removePendingMessage( message.group ) );

            if ( summary ) {
                Vue.set( this.summary, conversationId, summary );
            }

            return { messages, pending, summary };
        },

        async listAssistantConversationMessages(
            projectUuid: string,
            conversationId: string,
            options?: ContextRequestOptions,
        ) {
            const { messages } = await this.fetchAssistantConversationMessages(
                projectUuid,
                conversationId,
                options,
            );

            return messages;
        },

        async sendAssistantConversationMessage(
            projectUuid: string,
            conversationId: string,
            content: string | string[],
        ) {
            const pendingMessages = ( Array.isArray( content ) ? content : [content] ).map(
                ( content ) => {
                    return {
                        content: content,
                        failed: false,
                        group: '',
                        role: 'user' as const,
                        sent: false,
                        suggestions: [],
                        uuid: '',
                    };
                },
            );

            this.pendingMessages.push( ...pendingMessages );

            try {
                const group = await sendAssistantConversationMessage(
                    projectUuid,
                    conversationId,
                    content,
                );

                pendingMessages.forEach( ( message ) => {
                    message.sent = true;
                    message.group = group;
                } );
            } catch {
                pendingMessages.forEach( ( message ) => {
                    message.failed = true;
                } );
            }

            if ( !this.assistantPending ) {
                await this.startPolling( projectUuid, conversationId );
            }
        },

        async sendAssistantConversationCorkMessage( projectUuid: string, conversationId: string ) {
            try {
                await sendAssistantConversationCorkMessage( projectUuid, conversationId );
            } catch ( cause ) {
                throw new Error( 'Failed to cork conversation', { cause } );
            }

            if ( !this.assistantPending ) {
                let summary = undefined;
                let attempts = 0;

                while ( !summary && attempts < 30 ) {
                    attempts++;

                    await sleep( 1000 );

                    ( { summary } = await fetchAssistantConversationMessages(
                        projectUuid,
                        conversationId,
                    ) );
                }

                if ( !summary ) {
                    throw new Error( 'Failed to fetch assistant conversation summary' );
                }

                Vue.set( this.summary, conversationId, summary );
            }
        },

        removePendingMessage( group: string ) {
            this.pendingMessages = this.pendingMessages.filter(
                ( message ) => message.group !== group,
            );
        },

        resetPendingMessages() {
            this.pendingMessages.splice( 0 );
        },

        // endregion

        // region polling

        async startPolling(
            projectUuid: string,
            conversationId: string,
            options?: ContextRequestOptions,
        ) {
            if ( this.abortController ) {
                this.abortController.abort();
            }

            this.abortController = new AbortController();
            const { signal } = this.abortController;

            try {
                const { pending } = await this.fetchAssistantConversationMessages(
                    projectUuid,
                    conversationId,
                    {
                        ...options,
                        signal,
                    },
                );

                // Show typing indicator
                this.assistantPending = pending || this.pendingMessages.length > 0;
            } catch ( error ) {
                if ( !( error instanceof Error ) || signal.aborted ) {
                    this.abortController = undefined;
                    this.assistantPending = false;

                    return;
                }
            }

            if ( this.assistantPending && !this.abortController.signal.aborted ) {
                // Schedule the next poll and return early. This keeps the polling loop
                // running until the server responds with a different status code.
                this.pollingTimeout = setTimeout( () => {
                    void this.startPolling( projectUuid, conversationId );
                }, this.waitInterval ) as unknown as number;
            }
        },

        stopPolling() {
            if ( typeof this.pollingTimeout !== 'undefined' ) {
                clearTimeout( this.pollingTimeout );
            }

            if ( this.abortController ) {
                this.abortController.abort( {
                    reason: 'Polling stopped',
                } );
            }

            this.assistantPending = false;
        },

        // endregion
    },

    getters: {
        // region Projects

        projects( { apiProjects, apiCollaborators } ) {
            return apiProjects.map(
                ( project ) => new Project( project, apiCollaborators[project.uuid] ?? [] ),
            );
        },

        getProject( { apiCollaborators, apiProjects } ) {
            return ( projectUuid: string ) => {
                const project = apiProjects.find( ( { uuid } ) => uuid === projectUuid );

                if ( !project ) {
                    return null;
                }

                const collaborators = apiCollaborators[projectUuid] || [];

                return new Project( project, collaborators );
            };
        },

        otherProjects( { apiCollaborators, apiProjects } ) {
            return ( projectUuid: string ) => {
                return apiProjects
                    .filter( ( { uuid } ) => uuid !== projectUuid )
                    .map( ( project ) => new Project( project, apiCollaborators[project.uuid] || [] ) );
            };
        },

        getProjectCollaborators( { apiCollaborators } ) {
            return ( projectUuid: string ) => apiCollaborators[projectUuid] || [];
        },

        getDesiredAggregations( { aggregations } ) {
            return ( field: AggregatableField ) => aggregations[field] ?? null;
        },

        // endregion

        // region AI assistant

        getSetupAssistantConversation( { conversations, setupConversations } ) {
            return ( projectUuid: string ) =>
                projectUuid in conversations
                    ? conversations[projectUuid]?.find(
                          ( { uuid } ) => uuid === setupConversations[projectUuid],
                      )
                    : undefined;
        },

        getAssistantConversation( { conversations } ) {
            return ( projectUuid: string, conversationId: string ) =>
                conversations[projectUuid]?.find( ( { uuid } ) => uuid === conversationId );
        },

        getAssistantConversations( { conversations } ) {
            return ( projectUuid: string ) => conversations[projectUuid] || [];
        },

        /**
         * TODO: overhaul such getters which simply return state due to possible
         *       reactivity issues
         * @deprecated Use the store state directly instead
         * @param conversationMessages
         */
        getAssistantConversationMessages( { conversationMessages } ) {
            return ( conversationId: string ) => conversationMessages[conversationId] || [];
        },

        getAssistantConversationSummary( { summary } ) {
            return ( conversationId: string ) => summary[conversationId] || undefined;
        },

        // endregion
    },
} );

if ( import.meta.hot ) {
    import.meta.hot.accept( acceptHMRUpdate( useProjectsStore, import.meta.hot ) );
}
