/**
 * Error that indicates that the API version and
 * the current client version are out of sync.
 */
export default class ApiVersionError extends Error {
    constructor( serverVersion: string, clientVersion: string ) {
        super(
            'API server version ' +
                serverVersion +
                ' does not match current client version ' +
                clientVersion +
                '.',
        );
        this.name = 'ApiVersionError';
    }
}
