import Vue from 'vue';
import type { UserVuetifyPreset } from 'vuetify';
import Vuetify from 'vuetify/lib';
import type { VuetifyParsedTheme } from 'vuetify/types/services/theme';

export default createVuetify( {
    mobileBreakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 600,
            sm: 960,
            md: 1280,
            lg: 1920,
        },
    },

    icons: {
        iconfont: 'mdiSvg',
    },

    lang: {
        current: 'en',
        // locales: { en },
        // t: undefined as any,
    },

    rtl: false,

    theme: {
        default: 'light',

        options: {
            /**
             * CSP nonce
             * =========
             * Pages with the `script-src` or `style-src` CSP rules enabled may
             * require a nonce to be specified for embedded style tags.
             *
             * We set the nonce during the build process, so every release will
             * use a new nonce automatically.
             * By adding a nonce and CSP policy, we can control which style tags
             * may be executed on a page, providing additional security against
             * script injections.
             *
             * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/script-src
             * @see https://vuetifyjs.com/en/features/theme/#csp-nonce
             */
            cspNonce: import.meta.env.VITE_CSP_NONCE || undefined,

            /** @see https://vuetifyjs.com/en/features/theme/#minification */
            // minifyTheme: undefined,

            /** @see https://vuetifyjs.com/en/features/theme/#caching */
            themeCache: {
                // https://vuetifyjs.com/features/theme/#section-30ad30e330c330b730e5
                get: ( key: VuetifyParsedTheme ) => {
                    return localStorage.getItem( JSON.stringify( key ) );
                },
                set: ( key: VuetifyParsedTheme, value: string ) => {
                    localStorage.setItem( JSON.stringify( key ), value );
                },
            },

            /** @see https://vuetifyjs.com/en/features/theme/#custom-properties */
            customProperties: true,
        },

        themes: {
            light: {
                text: '#18273a',
                primary: {
                    lighten2: '#fff4e1',
                    lighten1: '#fae5bf',
                    base: '#e89500',
                    darken1: '#B96C00',
                },
                secondary: {
                    lighten3: '#e6efee',
                    lighten2: '#dbf3f6',
                    lighten1: '#b8dde1',
                    base: '#1e8ea5',
                },

                headline: '#001630',
                unseen: '#FFFFFF',

                accent: '#8e198d',
                success: {
                    base: '#6ad45d',
                    lighten1: '#E8F9E3',
                },
                error: '#ff5252',
                info: '#2196f3',
                warning: '#fb8c00',

                green: '#5adbc0',
                red: '#ff5c00',
                blue: '#429ffc',
                yellow: '#f9d71c',
                grey: {
                    lighten5: '#fbfbfb',
                    lighten4: '#fafafa',
                    lighten3: '#f5f5f5',
                    lighten2: '#e0e0e0',
                    lighten1: '#bdbdbd',
                    base: '#9e9e9e',
                    darken1: '#757575',
                    darken2: '#616161',
                    darken3: '#424242',
                    darken4: '#212121',
                },
                shade: '#fafafa',
            },

            dark: {
                primary: '#e89500',
                secondary: '#1e8ea5',
                accent: '#8e198d',
                error: '#ff5252',
                info: '#2196f3',
                success: {
                    base: '#4caf50',
                    lighten1: '#E8F9E3',
                },
                warning: '#fb8c00',

                headline: '#001630',
                unseen: '#FFFFFF',

                green: '#5adbc0',
                blue: '#429ffc',
                yellow: '#f9d71c',
                grey: {
                    lighten5: '#fcfcfc',
                    lighten4: '#eee',
                    lighten3: '#e0e0e0',
                    lighten2: '#bdbdbd',
                    lighten1: '#9e9e9e',
                    base: '#757575',
                    darken1: '#616161',
                    darken2: '#424242',
                    darken3: '#212121',
                    darken4: '#111',
                },
                shade: '#111',
            },
        },
    },
} );

export function createVuetify( options: UserVuetifyPreset ): Vuetify {
    Vue.use( Vuetify );

    return new Vuetify( options );
}
