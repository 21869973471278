import ApiError, { is429ApiError } from '@main/api/errors/ApiError';
import ApiMaximumRetriesExceededError from '@main/api/errors/ApiMaximumRetriesExceededError';
import ApiTimeoutError from '@main/api/errors/ApiTimeoutError';
import {
    type ContextRequestOptions,
    type CreateResourceContextRequestOptions,
    type HttpMethod,
    METHOD_DELETE,
    METHOD_GET,
    METHOD_PATCH,
    METHOD_POST,
    METHOD_PUT,
} from '@main/api/plugin/types/api';
import type {
    ApiResponse,
    ApiResponseWithData,
    ApiResponseWithErrors,
    Attributes,
    DefaultMeta,
    Included,
    Links,
    Meta,
    ResourceObject,
} from '@main/api/plugin/types/jsonApi';
import { useNotificationsStore } from '@main/store/stores/notifications';
import type { MaybePromise } from '@main/utilities/types';
import { matchClientVersion } from '@main/version';
import type { default as ky, Options } from 'ky';
import { HTTPError } from 'ky';

/**
 * Context Factory
 * ===============
 * Creates a context instance. This contains the implementations of the context
 * methods you'll use in the modules. It is invoked in the API plugin.
 */
export function contextFactory(
    client: typeof ky,
    cache: Map<string, Response>,
    errorHandler: ( message: string, error: Error ) => MaybePromise<unknown>,
) {
    const pending = new PendingApiRequests();

    /**
     * Parses a JSON response and catches serialization errors.
     */
    async function parseResponse<
        T extends Attributes = Attributes,
        M extends Meta = DefaultMeta,
        I extends Included = Included,
        L extends Links = Links,
    >( response: Response ) {
        let body: ApiResponseWithErrors | ApiResponseWithData<T, M, I, L>;

        try {
            body = ( await response.clone().json() ) as
                | ApiResponseWithErrors
                | ApiResponseWithData<T, M, I, L>;
        } catch ( error ) {
            if ( !isError( error ) ) {
                throw error;
            }

            const message = import.meta.env.PROD
                ? 'A server error occurred. Please retry later'
                : `Failed to parse response body: ${error.message}`;

            await errorHandler( message, error );

            throw error;
        }

        if ( responseHasErrors( body ) ) {
            const { title } = body.errors.shift() ?? {};

            throw new Error( title );
        }

        return body;
    }

    function extractSingleResourceObject<
        D extends Attributes,
        M extends Meta,
        I extends Included,
        L extends Links,
    >( body: ApiResponseWithData<D, M, I, L> ) {
        const { data, included } = body;

        if ( Array.isArray( data ) ) {
            throw new Error( 'Unexpected API response: Expected single resource object' );
        }

        data.included = included;

        return data;
    }

    // region Request
    /**
     * Performs an HTTP request using ky.
     *
     * @param method      HTTP request method
     * @param url         URL instance or relative string URI
     * @param options     Optional settings for Ky
     * @throws ApiError
     */
    async function request(
        this: void,
        method: HttpMethod,
        url: string | URL,
        options?: ContextRequestOptions,
    ) {
        const searchParams = sanitizeSearchParams( options?.searchParams );

        if (
            import.meta.env.DEV &&
            searchParams instanceof URLSearchParams &&
            ( import.meta.env.VITE_ENABLE_XDEBUG === 'true' ||
                import.meta.env.VITE_ENABLE_XDEBUG?.includes( url.toString() ) )
        ) {
            console.debug( `[http] Enabling Xdebug for request to "${url.toString()}"` );
            searchParams.set( 'XDEBUG_TRIGGER', '1' );
        }

        // Augment the options with the HTTP method
        const requestOptions = {
            credentials: 'include',
            ...options,
            method,
            searchParams,
        } satisfies Options;

        if ( options?.bypass ) {
            requestOptions.cache = 'no-store';
        }

        // Check whether there's a pending request to the same URL with
        // the same query parameters. We convert the URL instance to a string,
        // so we can compare URLs of different requests instead of
        // object instances.
        const pendingRequest = pending.get( url, requestOptions );

        if ( pendingRequest ) {
            return pendingRequest;
        }

        // Initialize the response variable outside the scope of the try-catch
        // block, so we can still return the response of successful call eventually.
        let response: Response;

        try {
            const pendingRequest = client( url, requestOptions );

            // If we're performing a GET request on the client here, store the
            // _promise_ of the pending request in the pending cache. That way,
            // we can attach parallel requests to the same resource and prevent
            // multiple executions.
            if ( method === METHOD_GET ) {
                pending.set( url, requestOptions, pendingRequest );
            }

            // Wait for the request to finish.
            response = await pendingRequest;

            // Make sure the current client version corresponds to the server version.
            await checkClientVersion( response, url );

            // Custom cache invalidation handling
            if ( options?.invalidates && options.invalidates.length > 0 ) {
                options.invalidates.forEach( ( pathname: string | RegExp ): void => {
                    if ( typeof pathname === 'string' ) {
                        cache.delete(
                            // Make sure to build the full URL from the endpoint
                            // URI that has been passed in the options
                            import.meta.env.VITE_API_URL + '/' + pathname.replace( /^\//, '' ),
                        );

                        return;
                    }

                    // If we have a regex, flush everything that matches
                    cache.forEach( ( _value, key ) => ( pathname.exec( key ) ? cache.delete( key ) : null ) );
                } );
            }
        } catch ( error ) {
            if ( !isError( error ) ) {
                throw error;
            }

            // ky attaches the response to the error if it received a response,
            // but with a status code indicating an error, we will extract an
            // eventual error message and hint from the response body.
            const errorResponse = isHttpError( error ) ? error.response : undefined;

            if ( errorResponse ) {
                // Make sure the current client version corresponds to the
                // server version.
                await checkClientVersion( errorResponse, url );
            }

            if ( error.name === 'TimeoutError' ) {
                throw new ApiTimeoutError();
            }

            if ( error instanceof ApiMaximumRetriesExceededError ) {
                if ( !options?.suppressErrorHandling ) {
                    await errorHandler( error.message, error );
                    console.error( `Request to ${url.toString()} failed: ${error.message}` );
                }

                throw error;
            }

            if ( !isHttpError( error ) ) {
                throw error;
            }

            let message = 'Unknown error';
            let correlationId = '[none]';

            // The response attached by ky contains a status code indicating an error.
            // We will extract an error message and hint from the response body.
            if ( errorResponse ) {
                // We await a clone of the response to prevent exhausting the
                // response stream.
                const rawResponseBody = await errorResponse.clone().text();
                let responseBody: ApiResponseWithErrors | undefined;

                try {
                    responseBody = rawResponseBody
                        ? ( JSON.parse( rawResponseBody ) as ApiResponseWithErrors )
                        : undefined;
                } catch ( error ) {
                    responseBody = { errors: [{ title: rawResponseBody }] };
                }

                // Check whether the response contains any well-formatted errors
                // and map them to their reasons if so
                if ( responseBody && responseHasErrors( responseBody ) ) {
                    const { errors } = responseBody;

                    message =
                        errors
                            .filter( ( { title } ) => !!title )
                            .map( ( { title } ) => ( title ?? '' ).replace( /\.$/, '' ) )
                            .join( '. ' ) + '.';

                    // If there's a correlation ID in there, we want it
                    correlationId = errors.find( ( { id } ) => !!id )?.id ?? '[none]';

                    // eslint-disable-next-line no-ex-assign
                    error = new ApiError( message, errors, errorResponse );
                }
            }

            if ( is429ApiError( error as ApiError ) ) {
                notify429();
            } else if ( !options?.suppressErrorHandling ) {
                await errorHandler( message, error as ApiError );

                if ( import.meta.env.DEV ) {
                    console.error(
                        `Request to ${url.toString()} failed: ${message}\n` +
                            `Correlation ID: ${correlationId}`,
                    );
                }
            } else if ( import.meta.env.DEV && errorResponse ) {
                console.warn( `API error ${errorResponse.status} is expected.` );
            } else if ( import.meta.env.DEV ) {
                console.error( 'Expected error but no response given.' );
            }

            // Re-throw the error to make sure dependent code fails here.
            throw error;
        } finally {
            if ( method === 'GET' ) {
                // Remove the pending promise: The request is no longer pending,
                // next one might have a different semantic meaning. Note that
                // this happens regardless of outcome, so new requests won't
                // fail due to a temporary error.
                pending.delete( url, requestOptions );
            }
        }

        return response;
    }

    // endregion

    return {
        /**
         * Fetches a list of items from the API.
         *
         * @param {string|URL}  url     URL instance or relative string URI
         * @param {Options}     options Optional settings for Ky
         */
        async all<
            T extends Attributes = Attributes,
            I extends Attributes = Attributes,
            M extends Meta = DefaultMeta,
            L extends Links = Links,
        >( this: void, url: string | URL, options?: ContextRequestOptions ) {
            const response = await request( METHOD_GET, url, options );
            const { data, included, meta, links } = await parseResponse<T, M, Included<I>, L>(
                response,
            );

            if ( !Array.isArray( data ) ) {
                throw new Error( 'Unexpected API response: Expected list of resource objects' );
            }

            return { data, included, links, meta };
        },

        // region All
        create: async function create<
            T extends Attributes = Attributes,
            I extends Attributes = Attributes,
            M extends Meta = DefaultMeta,
            O extends CreateResourceContextRequestOptions<
                boolean | undefined
            > = CreateResourceContextRequestOptions<boolean | undefined>,
        >(
            this: void,
            url: string | URL,
            attributes: Attributes,
            options?: O,
        ): Promise<
            ( O['returnResource'] extends true ? ResourceObject<T, M, Included<I>> : string ) | null
        > {
            const response = await request( METHOD_POST, url, {
                ...options,
                body: JSON.stringify( attributes ),
            } );

            // Return resource
            if ( options?.returnResource ) {
                const { data, included } = ( await response.json() ) as ApiResponseWithData<
                    T,
                    M,
                    Included<I>
                >;

                return { ...data, included } as
                    | ( O['returnResource'] extends true
                          ? ResourceObject<T, M, Included<I>>
                          : string )
                    | null;
            }

            // Return ID
            const idFromHeader = response.headers.get( 'Matchory-Resource-Id' );

            if ( idFromHeader ) {
                return idFromHeader as
                    | ( O['returnResource'] extends true
                          ? ResourceObject<T, M, Included<I>>
                          : string )
                    | null;
            }

            console.warn( `No "Matchory-Resource-Id" header in response from ${url.toString()}` );
            const locationHeader = response.headers.get( 'Location' );

            if ( !locationHeader ) {
                return null;
            }

            return ( new URL( locationHeader ).pathname.split( '/' ).pop() ?? null ) as
                | null
                | ( O['returnResource'] extends true ? ResourceObject<T, M, Included<I>> : string );
        } as {
            <
                T extends Attributes = Attributes,
                I extends Attributes = Attributes,
                M extends Meta = DefaultMeta,
            >(
                url: string | URL,
                attributes: Partial<T> | Attributes,
                options: CreateResourceContextRequestOptions<true> & { returnResource: true; },
            ): Promise<ResourceObject<T, M, Included<I>>>;
            <T extends Attributes = Attributes>(
                url: string | URL,
                attributes: Partial<T> | Attributes,
                options?: CreateResourceContextRequestOptions<false> | undefined,
            ): Promise<string | null>;
            <T extends Attributes = Attributes>(
                url: string | URL,
                attributes: Partial<T> | Attributes,
                options?: CreateResourceContextRequestOptions<undefined> | undefined,
            ): Promise<string | null>;
        },
        // endregion

        // region Single
        async destroy( this: void, url: string | URL, options?: ContextRequestOptions ) {
            await request( METHOD_DELETE, url, options );
        },
        // endregion

        // region Create
        async put<
            T extends Attributes = Attributes,
            I extends Attributes = Attributes,
            M extends Meta = DefaultMeta,
        >( this: void, url: string | URL, attributes: Attributes, options?: ContextRequestOptions ) {
            const response = await request( METHOD_PUT, url, {
                ...options,
                body: JSON.stringify( attributes ),
            } );

            if ( response.status === 204 ) {
                return { included: {} } as ResourceObject<T, M, Included<I>>;
            }

            const body = await parseResponse<T, M, Included<I>, Links>( response );

            return extractSingleResourceObject( body );
        },
        // endregion

        // region Update
        request,
        // endregion

        // region Put
        /**
         * Fetch a single item from the API.
         *
         * @param {string|URL}  url     URL instance or relative string URI
         * @param {Options}     options Optional settings for Ky
         */
        async single<
            T extends Attributes = Attributes,
            I extends Attributes = Attributes,
            M extends Meta = DefaultMeta,
        >( this: void, url: string | URL, options?: ContextRequestOptions ) {
            const response = await request( METHOD_GET, url, options );
            const body = await parseResponse<T, M, Included<I>, Links>( response );

            return extractSingleResourceObject( body );
        },
        // endregion

        // region Destroy
        async update<
            T extends Attributes = Attributes,
            I extends Attributes = Attributes,
            M extends Meta = DefaultMeta,
        >( this: void, url: string | URL, attributes: Attributes, options?: ContextRequestOptions ) {
            const response = await request( METHOD_PATCH, url, {
                ...options,
                body: JSON.stringify( attributes ),
            } );

            if ( response.status === 204 ) {
                return { included: {} } as ResourceObject<T, M, Included<I>>;
            }

            const body = await parseResponse<T, M, Included<I>, Links>( response );

            return extractSingleResourceObject( body );
        },
        // endregion
    };
}

function responseHasErrors( response: ApiResponse ): response is ApiResponseWithErrors {
    return ( response.errors && response.errors.length > 0 ) ?? false;
}

function sanitizeSearchParams(
    params: ContextRequestOptions['searchParams'],
): Options['searchParams'] {
    if ( !params ) {
        return new URLSearchParams();
    }

    if ( params instanceof URLSearchParams ) {
        return params;
    }

    if ( typeof params === 'string' ) {
        return new URLSearchParams( params );
    }

    if ( Array.isArray( params ) ) {
        if ( params.length === 0 ) {
            return params;
        }

        return params.filter( ( [, value] ) => typeof value !== 'undefined' );
    }

    if ( !iterable( params ) ) {
        return params as Options['searchParams'];
    }

    const sanitized = new URLSearchParams();

    // If we have search params, and they are a plain object, make sure it
    // does not contain any undefined values - the backend understands null
    // and null only for empty values.
    for ( const [name, value] of Object.entries( params ) ) {
        if ( typeof value === 'undefined' ) {
            continue;
        }

        if ( Array.isArray( value ) ) {
            value.forEach( ( item ) => sanitized.append( `${name}[]`, item ) );

            continue;
        }

        sanitized.append( name, value.toString() );
    }

    return sanitized;
}

function iterable<T>( object: unknown ): object is Iterable<T> {
    return !!( object && object.constructor === Object );
}

function isError( candidate: unknown ): candidate is Error {
    return candidate instanceof Error;
}

function isHttpError( candidate: Error ): candidate is HTTPError {
    return candidate instanceof HTTPError;
}

export function isHttpErrorStatus( candidate: Error, status: number ) {
    if ( !isHttpError( candidate ) ) {
        return false;
    }

    return candidate.response.status == status;
}

function notify429() {
    const store = useNotificationsStore();

    store.notify( {
        color: 'secondary',
        detail: `We are sorry we couldn't handle your request. Please wait for a minute and then reload the application.`,
        message: 'It seems you are making too many requests.',
        persistent: true,
        type: 'error',
    } );
}

/**
 * Holds pending API requests.
 */
class PendingApiRequests {
    private pending: Map<string, Promise<Response>>;

    constructor() {
        this.pending = new Map<string, Promise<Response>>();
    }

    public set( url: string | URL, options: ContextRequestOptions, request: Promise<Response> ) {
        this.pending.set( this.key( url, options ), request );
    }

    public has( url: string | URL, options: ContextRequestOptions ) {
        return this.pending.has( this.key( url, options ) );
    }

    public get( url: string | URL, options: ContextRequestOptions ): Promise<Response> | undefined {
        return this.pending.get( this.key( url, options ) );
    }

    public delete( url: string | URL, options: ContextRequestOptions ) {
        this.pending.delete( this.key( url, options ) );
    }

    private key( url: string | URL, options: ContextRequestOptions ): string {
        const searchParams = new URLSearchParams(
            ( options.searchParams as unknown as URLSearchParams ) || {},
        );

        return url.toString() + '?' + searchParams.toString();
    }
}

async function checkClientVersion( response: Response, url: string | URL ) {
    const headerName = 'Matchory-Release';
    const release = response.headers.get( headerName );

    if ( !release ) {
        throw new Error( `The "${headerName}" header is missing from the response` );
    }

    // Some urls should pass, even if the version is not matching.
    if ( url.toString().includes( 'oauth/signout' ) ) {
        return;
    }

    await matchClientVersion( release );
}
